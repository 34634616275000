<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <!-- <img src="assets/img/courses/img1.jpg" alt="image"> -->
                    <p><i>This Privacy Policy was last updated on June 8, 2024.</i></p>

                    <h3>Privacy Policy</h3>
                    <p>This Privacy Policy (“Policy”) governs the manner in which Buddica Global Solutions Private Limited (the “Company” or “We”) collects, 
                        gains access to, uses maintains, and discloses information including personal information of the users (each, a “User”) of various services (“Services”) provided by the Company, 
                        either through the website of the Company or through mobile applications or network telecom operators. Please note that the scope of this Policy is limited to the aforesaid 
                        information collected or received by the Company through your use of the Services. By using the Service, you are expressing your agreement to this Policy and the processing of 
                        your information, including your personal information, in the manner provided in this Policy. If you do not agree to these terms, please do not use the Service and do not provide the permissions when specifically asked by the Company.
                    </p>

                    <h3>What we Collect</h3>
                    <p>
                        We may collect and/or gain access to and/or record certain information including personal
information from or of User in a variety of ways, including, but not limited to,when User access and uses the Services and in connection with other activities,services, features or resources we make available on the Services. These information may relate to and include but not limited to your name, email address,
address, phone number, your unique device ID (persistent / non-persistent), hardware type, international mobile equipment identity (“IMEI”), the version of your operating system (“OS”), your device name, your email address (if you have connected to Facebook or Google+), and your location (based on your Internet Protocol (“IP”) address), contact list (on your mobile phone), applications installed by User through our mobile application, applications uninstalled by User, all other applications installed by User on his/her device, foreground running apps/process (RUN event; We update server about the app downloaded/installed by our mobile application), read SMS, network information, User behaviour analysis, demographic information (such as preferences and interests etc), Credit/Debit Card information of User for Internet banking (We do not store credit/debit card information on our servers) [hereinafter referred to as “Personal Information”]. Users can always refuse to supply Personal Information, however, it may prevent them from engaging in certain Services related activities.

                    </p>



                    <h3>What we do with the Personal Information we gather?</h3>
                    <pre>	
• Administer the Services.
• Personalize the Services for User.
• End to User direct links to the Services.
• Process transactions.
• Process installation.
• Send User our newsletter.
• Develop, deliver, and improve our products, services, content, and advertising.
• Send important notices, such as communications about purchases/downloads and changes to our Policy.
• Auditing, data analysis, and research to improve the Services.
• Troubleshooting and helping us to understand usage trends.
• Send alerts to User.
• Marketing and promotion of the Services.
                    </pre>

                    <h3>Collection and use of non-personal information</h3>
                    <p>We may collect non-personal information about Users whenever they use and interact with the Services. Non-personal information may include the browser name, the type of computer, and technical information about means used by the User to connect to our Services, such as the information about the operating system and the internet service providers utilized and other similar information.</p>
                    <p>This information is aggregated and used to help us provide more useful information to the User and to understand which part of the Services, its products, and services are of most interest.</p>
                    <p>If we do combine non-personal information with Personal Information, the combined information will be treated as Personal Information for as long as it remains combined.</p>

                    <h3>Sharing of information with Authorities</h3>
                    <p>We may share Personal Information as well as non-personal information of the User with the courts, police authorities, or any other government/regulatory/statutory authority, in case these are required by them in relation to any proceedings pending before them.</p>

                    <h3>Security</h3>
                    <p>We are committed to ensuring that the User’s Personal Information is secure. We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of the User's Personal Information, username, password, transaction information, and data stored in our Services.</p>
                    <p>When a User uses services and products offered by us through the Services, the Personal Information shared by the User is visible to the other users and can be read, collected, or used by them. The User is responsible for the Personal Information User chooses to submit in these instances. For example, if the User lists his/her name and email address in a blog/forum posting, that information is public. Users are requested to take care when using these features.</p>

                    <h3>INFORMATION RETENTION PERIOD</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>INFORMATION DISCLOUSERS</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Anychanges to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>INFORMATION BREACH</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>REVIEW YOUR PERSONAL & SENSATIVE PERSONAL INFORMATION</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>INDEMNITY</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>FORCE MAJEURE</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>JURISDICATION</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>


                    <h3>USER DISCRETION AND CHOICE / opt –out</h3>
                    <p>Our Privacy Policy is subject to change at any time without notice and the same shall be updated
                        and available within Happy Pay App and on its website at www.happypay.livefrom time to time.
                        Though every effort would be made to keep you informed of the changes, if any, to the policy,
                        you are kindly advised to review the policy periodically. Any changes to the Policy will become
                        effective upon posting of the revised Policy on the Internet, accessible through Happy Pay
                        Platform.</p>

                    <h3>Advertising</h3>
                    <p>Advertisement appearing on the Services may be delivered to the User by advertising partners, who may set cookies. These cookies allow the ad server to recognize the User computer each time they send the User an online advertisement to compile non-personal information about the User or others who use the User computer. This information allows advertisement networks to, among other things, deliver targeted advertisements that they believe will be of most interest to the User. This Policy does not cover the use of cookies by any advertisers..</p>

                    <h3>Changes to this privacy policy                    </h3>
                    <p>The Company has the discretion to update this Policy at any time. We encourage User to frequently check this page for any changes to stay informed about how we are helping to protect the Personal Information we collect. User acknowledges and agrees that it is his/her responsibility to review this Policy periodically and become aware of modifications.</p>

                    <h3>User acceptance of these terms</h3>
                    <p>By using the Services, User signifies his/her acceptance of this Policy. If the User does not agree to this Policy, please do not use the Services. User continued use of the Services following the posting of changes to this Policy will be deemed User acceptance of those changes.</p>





                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/about-1">About Us</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/purchase-guide">Purchase Guide</a></li>
                            <li class="active"><a routerLink="/privacy-policy">Privacy Policy</a></li>
                            <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        </ul>
                    </section>
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall"><a routerLink="/single-courses-1">Deep Learning A-Z™: Hands-On Artificial Neural Networks</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</div>